import React, { useEffect, useState } from "react";

/**
 * Componente para registrar o horário atual.
 * @return {void} retorna o horário atual.
 */
export function DataAtual() {
  const [dataHoraAtual, setDataHoraAtual] = useState(new Date());

  const formatoDataHora = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const dataHoraFormatada = new Intl.DateTimeFormat(
    'pt-BR',
    formatoDataHora
  ).format(dataHoraAtual);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDataHoraAtual(new Date());
    }, 1000); // Atualiza a cada 1000 milissegundos (1 segundo)

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []);

  return <>{dataHoraFormatada}</>;
}

export default DataAtual;