import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ItemsMenu } from '../../componentes/ItemsMenu/ItemsMenu';
import { useAuth } from '../../hooks/Auth';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import Logo from '../../media/logo.png';
import { mobile, paramTemaCorPrimaria } from '../../uteis/Parametros';
import DataAtual from '../DataAtual/DataAtual';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

/**
 * @return {object}
 */
export function MenuSuperior() {
  const [open, setOpen] = React.useState(!mobile);
  const [desconectarUsuario] = useFuncoes();
  const [usuario] = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDesconectar = () => {
    setAnchorElUser(null);
    desconectarUsuario();
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (mobile) {
      setOpen(false);
    }
  }, [location]);

  useEffect(() => {
    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]'
    );
    if (themeColorMetaTag) {
      themeColorMetaTag.setAttribute('content', paramTemaCorPrimaria);
    }

    return () => {
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', '#ffffff');
      }
    };
  }, []);

  return (
    <>
      <AppBar position='absolute' open={open} id='fk-header'>
        <Toolbar
          sx={{
            pr: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <DataAtual />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              {usuario && (
                <Chip
                  onClick={handleOpenUserMenu}
                  avatar={<Avatar>{usuario.usuario}</Avatar>}
                  label={usuario.usuario}
                  sx={{ color: 'white' }}
                />
              )}
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleDesconectar}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary='Desconectar' />
                </MenuItem>
              </Menu>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          maxHeight: window.innerHeight,
          height: mobile ? window.innerHeight : 'unset',
          position: mobile ? 'absolute' : 'unset',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <div className='d-flex w-100 justify-content-center'>
            <Link to={'/'}>
              <img src={Logo} width={130} />
            </Link>
          </div>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component='nav' sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
          <ItemsMenu />
        </List>
      </Drawer>
    </>
  );
}

export default MenuSuperior;
