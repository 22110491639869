import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function VendasProvider({ children }) {
  const [vendas, setVendas] = useState();

  return (
    <Ctx.Provider value={[vendas, setVendas]}>{children}</Ctx.Provider>
  );
}

VendasProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useVendas = () => {
  return useContext(Ctx);
};
