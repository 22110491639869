import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useItems } from '../../../hooks/Items';
import { removerItemDoState } from '../../../uteis/Funcoes';

const url = 'items/excluir';
const titulo = { 1: 'Alterar Bitola', 2: 'Alterar Motorista' };
const tipoPorTipo = { 1: 'Bitola', 2: 'Motorista' };
const botaoCadastrarMensagem = 'Excluir';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {number} tipo
 * @param {any} children
 * @return {children} Hooks.
 */
export function ExcluirItemProvider({ tipo, children }) {
  const [alvo, setExcluirItem] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setItems] = useItems();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setExcluirItem();
  }

  /**
   */
  function handleEnviar() {
    setEnviando(true);
    const data = { id: alvo.id };

    api
      .post(url, data)
      .then((r) => {
        setItems((prev) => removerItemDoState(alvo.id, prev));

        toast.success(tipoPorTipo[tipo] + ' excluída com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setExcluirItem]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo[tipo]}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <DialogContentText>
              Você tem certeza que deseja excluir?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant='contained'
              color='error'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoCadastrarMensagem}
            </LoadingButton>
            <Button
              color='secondary'
              variant='outlined'
              onClick={handleFechar}
              disabled={enviando}
            >
              {botaoCancelarMensagem}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

ExcluirItemProvider.propTypes = {
  tipo: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export const useExcluirItem = () => {
  return useContext(Ctx);
};
