import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useCompras } from '../../../hooks/Compras';
import { useFornecedores } from '../../../hooks/Fornecedores';
import { useItems } from '../../../hooks/Items';
import {
  alterarItemNoState,
  formatarValorParaReais,
} from '../../../uteis/Funcoes';
import { NovaVendaProvider } from '../../Vendas/Acoes/Novo';

const url = 'compras/alterar';
const titulo = 'Alterar Compra';
const botaoCadastrarMensagem = 'Alterar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarCompraProvider({ children }) {
  const [alvo, setAlterarCompra] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setCompras] = useCompras();
  const [items] = useItems();
  const [fornecedores] = useFornecedores();
  const [total, setTotal] = useState({ peso: null, valor: null });

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAlterarCompra();
  }

  /**
   * @param {object} e
   */
  function handlePeso(e) {
    setTotal((prev) => ({ peso: e.target.value, valor: prev.valor }));
  }

  /**
   * @param {object} e
   */
  function handleTotal(e) {
    setTotal((prev) => ({ peso: prev.peso, valor: e.target.value }));
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    if (alvo) {
      data.append('id', alvo.id);
    }

    api
      .post(url, data)
      .then((r) => {
        setCompras((prev) => alterarItemNoState(r, prev));

        toast.success('Compra alterada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      setTotal(JSON.parse(alvo.total));
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setAlterarCompra]}>
      <NovaVendaProvider>
        {alvo && (
          <Dialog
            open={alvo != null}
            onClose={handleFechar}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle>{titulo}</DialogTitle>
            <DialogContent sx={{ overflow: 'visible' }}>
              <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
                <input name='total' hidden value={JSON.stringify(total)} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel required>Motorista</InputLabel>
                      <Select
                        label='Motorista'
                        name='motorista'
                        defaultValue={alvo.motorista}
                        required
                      >
                        {items
                          .filter((item) => item.tipo === 2)
                          .map((item, index) => (
                            <MenuItem
                              key={`item-map-motorista-${index}`}
                              value={item.id}
                            >
                              {item.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel required>Bitola</InputLabel>
                      <Select
                        label='Bitola'
                        name='bitola'
                        defaultValue={alvo.bitola}
                        required
                      >
                        {items
                          .filter((item) => item.tipo === 1)
                          .map((item, index) => (
                            <MenuItem
                              key={`item-map-bitola-${index}`}
                              value={item.id}
                            >
                              {item.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      required
                      fullWidth
                      name='ticket'
                      label='Ticket'
                      autoComplete='off'
                      defaultValue={alvo.ticket}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      required
                      fullWidth
                      name='nota'
                      label='Nota'
                      autoComplete='off'
                      defaultValue={alvo.nota}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel required>Fornecedor</InputLabel>
                      <Select
                        label='Fornecedor'
                        name='fornecedor'
                        defaultValue={alvo.fornecedor}
                        required
                      >
                        {fornecedores.map((item, index) => (
                          <MenuItem
                            key={`item-map-fornecedor-${index}`}
                            value={item.id}
                          >
                            {item.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ width: '100%', opacity: 1 }} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      required
                      fullWidth
                      label='Tonelada'
                      autoComplete='off'
                      type='number'
                      onChange={handlePeso}
                      defaultValue={JSON.parse(alvo.total).peso}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>R$</InputAdornment>
                        ),
                      }}
                      inputProps={{ step: 'any' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={0.5}>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      x
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      required
                      fullWidth
                      label='Preço'
                      autoComplete='off'
                      type='number'
                      onChange={handleTotal}
                      defaultValue={JSON.parse(alvo.total).valor}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>R$</InputAdornment>
                        ),
                      }}
                      inputProps={{ step: 'any' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={0.5}>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      =
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      required
                      fullWidth
                      label='Valor total'
                      autoComplete='off'
                      aria-readonly
                      value={
                        total.valor && total.peso
                          ? 'R$ ' +
                            formatarValorParaReais(total.valor * total.peso)
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <InputLabel required>Pago ?</InputLabel>
                      <Select
                        label='Pago ?'
                        name='pago'
                        defaultValue={alvo.pago}
                        required
                      >
                        <MenuItem value={0}>Não</MenuItem>
                        <MenuItem value={1}>Sim</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      name='observacao'
                      label='Observacao'
                      autoComplete='off'
                      multiline
                      rows={8}
                      defaultValue={alvo.observacao}
                    />
                  </Grid>
                </Grid>
                <DialogActions sx={{ p: 0, mt: 3 }}>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={enviando}
                  >
                    {botaoCadastrarMensagem}
                  </LoadingButton>
                  <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    onClick={handleFechar}
                    disabled={enviando}
                  >
                    {botaoCancelarMensagem}
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {children}
      </NovaVendaProvider>
    </Ctx.Provider>
  );
}

AlterarCompraProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlterarCompra = () => {
  return useContext(Ctx);
};
