import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuSuperior from './componentes/MenuSuperior/MenuSuperior';
import Inicial from './paginas/Inicial/Inicial';
import './global.css';
import { useAuth } from './hooks/Auth';
import Usuarios from './paginas/Usuarios/Usuarios';
import { retornarCookie } from './uteis/Funcoes';
import { paramCookieMaster } from './uteis/Parametros';
import Fornecedores from './paginas/Fonecedores/Fornecedores';
import Clientes from './paginas/Clientes/Clientes';
import Bitolas from './paginas/Bitolas/Bitolas';
import Motoristas from './paginas/Bitolas/Motoristas';
import Compras from './paginas/Compras/Compras';
import Vendas from './paginas/Vendas/Vendas';

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
function PaginaRouter() {
  return (
    <Routes>
      <Route index element={<Inicial />} />
      <Route path="usuarios" element={<Usuarios />} />
      <Route path="fornecedores" element={<Fornecedores />} />
      <Route path="clientes" element={<Clientes />} />
      <Route path="bitolas" element={<Bitolas />} />
      <Route path="motoristas" element={<Motoristas />} />
      <Route path="compras" element={<Compras />} />
      <Route path="vendas" element={<Vendas />} />
    </Routes>
  );
}

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
export default function App() {
  const [, , , setMaster] = useAuth();

  useEffect(() => {
    if (Boolean(retornarCookie(paramCookieMaster))) setMaster(true);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuSuperior />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          background: '#F2F2F7',
        }}
      >
        <Toolbar />
        <Container className='fk-container' maxWidth='xl' sx={{ mb: 4 }}>
          <PaginaRouter />
        </Container>
      </Box>
    </Box>
  );
}
