import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ptBR } from '@mui/x-date-pickers/locales';
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import {
    formatarDataHora,
  formatarValorParaReais,
  gerarPlanilhaExcel,
} from '../../../uteis/Funcoes';
import { useItems } from '../../../hooks/Items';
import { useFornecedores } from '../../../hooks/Fornecedores';

const url = 'compras/relatorio';
const titulo = 'Relatório Compras';
const botaoCadastrarMensagem = 'Gerar relatório';
const botaoCancelarMensagem = 'Cancelar';

const simNaoLista = ['Não', 'Sim'];

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function RelatorioCompra() {
  const [alvo, setAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [items] = useItems();
  const [fornecedores] = useFornecedores();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  /**
   */
  function handleAbrir() {
    setAlvo(true);
  }

  /**
   * @param {object} e
   */
  function handleEnviar(e) {
    e.preventDefault();
    setEnviando(true);
    const data = new FormData(e.target);

    api
      .post(url, data)
      .then((r) => {
        const resposta = r;

        resposta.forEach((item) => {
          if (item.motorista) {
            item.motorista = items.find(
              (pItem) => pItem.id === item.motorista
            ).nome;
          }
          if (item.bitola) {
            item.bitola = items.find((pItem) => pItem.id === item.bitola).nome;
          }
          if (item.total) {
            item.total =
              'R$ ' +
              formatarValorParaReais(
                JSON.parse(item.total).peso * JSON.parse(item.total).valor
              );
          }
          if (item.fornecedor) {
            item.fornecedor = fornecedores.find(
              (pItem) => pItem.id === item.fornecedor
            ).nome;
          }
          if (item.pago == 0 || item.pago == 1) {
            item.pago = simNaoLista[Number(item.pago)]
          }
          if (item.criadoEm) {
            item.criadoEm = formatarDataHora(item.criadoEm)
          }
          if (item.excluido == 0 || item.excluido == 1) {
            item.excluido = simNaoLista[Number(item.excluido)]
          }
        });

        gerarPlanilhaExcel(resposta);

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <>
      <Dialog
        open={alvo != null}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={
                    ptBR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{ paddingTop: 0, overflow: 'visible' }}
                  >
                    <DatePicker
                      label='Data inicial'
                      name='data_inicial'
                      sx={{ width: '100%' }}
                      format={'D/M/YYYY'}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{ paddingTop: 0, overflow: 'visible' }}
                  >
                    <DatePicker
                      label='Data final'
                      name='data_final'
                      sx={{ width: '100%' }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Colunas</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      value={'id'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Id'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'motorista'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Motorista'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'bitola'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Bitola'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'total'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Total'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'ticket'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Ticket'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'nota'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Nota'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'fornecedor'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Fornecedor'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'pago'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Pago'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'criado_em'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Data de cadastro'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={'excluido'}
                      control={<Checkbox defaultChecked name='colunas[]' />}
                      label='Excluído'
                      labelPlacement='end'
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <FormLabel id='demo-radio-buttons-group-label'>
                    Incluir itens excluídos?
                  </FormLabel>
                  <RadioGroup defaultValue={false} name='excluidos'>
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Não'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Sim'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                color='secondary'
                variant='outlined'
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant='contained' onClick={handleAbrir}>
        Gerar relatório
      </Button>
    </>
  );
}
