import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import React from 'react';
import { mobile, paramItemsMenu } from '../../uteis/Parametros';
import { Link } from 'react-router-dom';

/**
 * @return {node}
 */
function Inicial() {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      p={2}
    >
      {paramItemsMenu.map(({ url, icone, titulo }, index) => (
        <Grid key={`item-menu-inicial-${index}`} item xs={2} sm={4} md={4}>
          <Paper
            sx={{
              height: 100,
              display: 'flex',
              alignItems: 'center',
            }}
            i
          >
            <Link
              key={`item-manu-${index}`}
              to={url}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                height: '100%',
                width: '100%',
              }}
            >
              <ListItemButton
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: mobile ? 'column' : 'row',
                  justifyContent: mobile ? 'center' : 'unset',
                }}
                disableRipple
              >
                <ListItemIcon className='fk-item-icon'>{icone}</ListItemIcon>
                <ListItemText className='fk-item-text' primary={titulo} />
              </ListItemButton>
            </Link>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

Inicial.propTypes = {};

export default Inicial;
