import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useVendas } from '../../../hooks/Vendas';
import { alterarItemNoState } from '../../../uteis/Funcoes';

const url = 'vendas/alterar-status';
const titulo = 'Alterar Status da Venda';
const botaoCadastrarMensagem = 'Alterar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarStatusVendaProvider({ children }) {
  const [alvo, setAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setVendas] = useVendas();
  const [status, setStatus] = useState(JSON.parse(alvo?.status ?? '[]'));
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  /**
   * @param {object} e
   */
  function handleResposta(e) {
    const valor = JSON.parse(e.target.value);
    const prevResposta = structuredClone(status);

    if (status[valor[0]] !== valor[1] && !status.includes(valor[1])) {
      prevResposta.splice(valor[0], 0, valor[1]);
    } else {
      const index = status.findIndex((item) => item === valor[1]);
      prevResposta.splice(index, 1);
    }

    setStatus(prevResposta);
  }

  /**
   */
  function handleEnviar() {
    setEnviando(true);
    const data = { id: alvo.id, status: JSON.stringify(status) };

    api
      .post(url, data)
      .then((r) => {
        setVendas((prev) => alterarItemNoState(r, prev));

        toast.success('Status da venda alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      setStatus(JSON.parse(alvo.status));
    } else {
      setStatus();
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormGroup onChange={handleResposta}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={JSON.parse(alvo.status).includes(1)}
                        />
                      }
                      label='Foto do ticket encaminhada para o fornecedor'
                      value={JSON.stringify([0, 1])}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={JSON.parse(alvo.status).includes(2)}
                        />
                      }
                      label='Ticket original em mãos'
                      value={JSON.stringify([1, 2])}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant='contained'
              loading={enviando}
              onClick={handleEnviar}
            >
              {botaoCadastrarMensagem}
            </LoadingButton>
            <Button
              color='secondary'
              variant='outlined'
              onClick={handleFechar}
              disabled={enviando}
            >
              {botaoCancelarMensagem}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

AlterarStatusVendaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlterarStatusVenda = () => {
  return useContext(Ctx);
};
