import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from './Api';
import { useAuth } from './Auth';
import { useClientes } from './Clientes';
import { useFornecedores } from './Fornecedores';
import { useUsuarios } from './Usuarios';
import { useItems } from './Items';
import { useCompras } from './Compras';
import { useVendas } from './Vendas';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function FuncoesProvider({ children }) {
  const [, setUsuario] = useAuth();
  const [desconectando, setDesconectando] = useState(false);
  const [usuarios, setUsuarios] = useUsuarios();
  const [fornecedores, setFornecedores] = useFornecedores();
  const [clientes, setClientes] = useClientes();
  const [items, setItems] = useItems();
  const [compras, setCompras] = useCompras();
  const [vendas, setVendas] = useVendas();

  const ApiController = useApi();
  const api = new ApiController();

  const navigate = useNavigate();

  /**
   */
  function desconectarUsuario() {
    setDesconectando(true);
    api
      .post('autenticacao/desconectar')
      .then((r) => {
        setDesconectando(false);
        setUsuario(null);
        navigate('/');
      })
      .catch((e) => {
        setDesconectando(false);
      });
  }

  /**
   */
  function usuariosFetch() {
    if (!usuarios) {
      if (usuarios === null) {
        setUsuarios(undefined);
      }

      api
        .post('usuarios/listagem')
        .then((r) => {
          setUsuarios(r);
        })
        .catch((e) => {
          setUsuarios(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function fornecedoresFetch() {
    if (!fornecedores) {
      if (fornecedores === null) {
        setFornecedores(undefined);
      }

      api
        .post('fornecedores/listagem')
        .then((r) => {
          setFornecedores(r);
        })
        .catch((e) => {
          setFornecedores(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function clientesFetch() {
    if (!clientes) {
      if (clientes === null) {
        setClientes(undefined);
      }

      api
        .post('clientes/listagem')
        .then((r) => {
          setClientes(r);
        })
        .catch((e) => {
          setClientes(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function itemsFetch() {
    if (!items) {
      if (items === null) {
        setItems(undefined);
      }

      api
        .post('items/listagem')
        .then((r) => {
          setItems(r);
        })
        .catch((e) => {
          setItems(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function comprasFetch() {
    if (!compras) {
      if (compras === null) {
        setCompras(undefined);
      }

      api
        .post('compras/listagem')
        .then((r) => {
          setCompras(r);
        })
        .catch((e) => {
          setCompras(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function vendasFetch() {
    if (!vendas) {
      if (vendas === null) {
        setVendas(undefined);
      }

      api
        .post('vendas/listagem')
        .then((r) => {
          setVendas(r);
        })
        .catch((e) => {
          setVendas(null);
          console.error(e);
        });
    }
  }

  return (
    <Ctx.Provider
      value={[
        desconectarUsuario,
        usuariosFetch,
        fornecedoresFetch,
        clientesFetch,
        itemsFetch,
        comprasFetch,
        vendasFetch,
      ]}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={desconectando}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {children}
    </Ctx.Provider>
  );
}

FuncoesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFuncoes = () => {
  return useContext(Ctx);
};
