import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useClientes } from '../../../hooks/Clientes';
import { useItems } from '../../../hooks/Items';
import { useVendas } from '../../../hooks/Vendas';
import {
  alterarItemNoState,
  formatarValorParaReais,
} from '../../../uteis/Funcoes';

const url = 'vendas/alterar';
const titulo = 'Alterar Venda';
const botaoCadastrarMensagem = 'Alterar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarVendaProvider({ children }) {
  const [alvo, setAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setVendas] = useVendas();
  const [items] = useItems();
  const [clientes] = useClientes();
  const [total, setTotal] = useState({ peso: null, valor: null });

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  /**
   * @param {object} e
   */
  function handlePeso(e) {
    setTotal((prev) => ({ peso: e.target.value, valor: prev.valor }));
  }

  /**
   * @param {object} e
   */
  function handleTotal(e) {
    setTotal((prev) => ({ peso: prev.peso, valor: e.target.value }));
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    if (alvo) {
      data.append('id', alvo.id);
    }

    api
      .post(url, data)
      .then((r) => {
        setVendas((prev) => alterarItemNoState(r, prev));

        toast.success('Venda alterada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (alvo) {
      setTotal(JSON.parse(alvo.total));
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
              <input name='total' hidden value={JSON.stringify(total)} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel required>Motorista</InputLabel>
                    <Select
                      label='Motorista'
                      name='motorista'
                      defaultValue={alvo.motorista}
                      required
                    >
                      {items
                        .filter((item) => item.tipo === 2)
                        .map((item, index) => (
                          <MenuItem
                            key={`item-map-motorista-${index}`}
                            value={item.id}
                          >
                            {item.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel required>Cliente</InputLabel>
                    <Select
                      label='Cliente'
                      name='cliente'
                      defaultValue={alvo.cliente}
                      required
                    >
                      {clientes.map((item, index) => (
                        <MenuItem
                          key={`item-map-cliente-${index}`}
                          value={item.id}
                        >
                          {item.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ width: '100%', opacity: 1 }} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    fullWidth
                    label='Tonelada'
                    autoComplete='off'
                    type='number'
                    onChange={handlePeso}
                    defaultValue={JSON.parse(alvo.total).peso}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>R$</InputAdornment>
                      ),
                    }}
                    inputProps={{ step: 'any' }}
                  />
                </Grid>
                <Grid item xs={12} sm={0.5}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    x
                  </p>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    fullWidth
                    label='Preço'
                    autoComplete='off'
                    type='number'
                    onChange={handleTotal}
                    defaultValue={JSON.parse(alvo.total).valor}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>R$</InputAdornment>
                      ),
                    }}
                    inputProps={{ step: 'any' }}
                  />
                </Grid>
                <Grid item xs={12} sm={0.5}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    =
                  </p>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    required
                    fullWidth
                    label='Valor total'
                    autoComplete='off'
                    aria-readonly
                    value={
                      total.valor && total.peso
                        ? 'R$ ' +
                          formatarValorParaReais(total.valor * total.peso)
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ width: '100%', opacity: 1 }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    name='total_florestal'
                    label='Total Florestal'
                    autoComplete='off'
                    type='number'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>R$</InputAdornment>
                      ),
                    }}
                    defaultValue={alvo.totalFlorestal}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel required>Recebido ?</InputLabel>
                    <Select
                      label='Recebido ?'
                      name='recebido'
                      defaultValue={alvo.recebido}
                      required
                    >
                      <MenuItem value={0}>Não</MenuItem>
                      <MenuItem value={1}>Sim</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name='observacao'
                    label='Observacao'
                    autoComplete='off'
                    multiline
                    rows={8}
                    defaultValue={alvo.observacao}
                  />
                </Grid>
              </Grid>
              <DialogActions sx={{ p: 0, mt: 3 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={enviando}
                >
                  {botaoCadastrarMensagem}
                </LoadingButton>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  onClick={handleFechar}
                  disabled={enviando}
                >
                  {botaoCancelarMensagem}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

AlterarVendaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlterarVenda = () => {
  return useContext(Ctx);
};
