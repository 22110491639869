import { Button, Stack, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useClientes } from '../../hooks/Clientes';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useItems } from '../../hooks/Items';
import { useVendas } from '../../hooks/Vendas';
import { formatarDataHora, formatarValorParaReais } from '../../uteis/Funcoes';
import Acoes from './Acoes';
import { AlterarVendaProvider } from './Acoes/Alterar';
import { ExcluirVendaProvider } from './Acoes/Excluir';
import { NovaVendaProvider, useNovaVenda } from './Acoes/Novo';
import { VisualizarVendaProvider } from './Acoes/Visualizar';
import { pagoRecebido, paramStatus } from '../../uteis/Parametros';
import { AlterarStatusVendaProvider } from './Acoes/AlterarStatus';
import { mobile } from '../../uteis/Parametros';

const titulo = 'Vendas';
const botaoNovoItem = 'Nova venda';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Motorista</TableCell>
      <TableCell align='center'>Total</TableCell>
      <TableCell align='center'>Status</TableCell>
      <TableCell align='center'>Total Florestal</TableCell>
      <TableCell align='center'>Cliente</TableCell>
      <TableCell align='center'>Recebido</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'9ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'3ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovaVendaAberto] = useNovaVenda();
  const [items] = useItems();
  const [clientes] = useClientes();

  /**
   */
  function handleNovoFornecedor() {
    setNovaVendaAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        {listagem && items && clientes && (
          <Button variant='contained' onClick={handleNovoFornecedor}>
            {botaoNovoItem}
          </Button>
        )}
      </Stack>
      <TableContainer>
        <Table size='small' aria-label='caption table'>
          <TableHead>
            <ColunasHead />
          </TableHead>
          <TableBody>
            {listagem && items && clientes ? (
              listagem.map((venda, index) => (
                <TableRow
                  key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                >
                  <TableCell align='center'>{venda.id}</TableCell>
                  <TableCell align='center'>
                    {items.find((item) => item.id == venda.motorista).nome}
                  </TableCell>
                  <TableCell align='center'>
                    R${' '}
                    {formatarValorParaReais(
                      JSON.parse(venda.total).peso *
                        JSON.parse(venda.total).valor
                    )}
                  </TableCell>
                  <TableCell align='center' sx={{ color: 'red' }}>
                    {JSON.parse(venda.status).map((item) => paramStatus[item])}
                  </TableCell>
                  <TableCell align='center'>
                    R$ {formatarValorParaReais(Number(venda.totalFlorestal))}
                  </TableCell>
                  <TableCell align='center'>
                    {
                      clientes.find((cliente) => cliente.id == venda.cliente)
                        .nome
                    }
                  </TableCell>
                  <TableCell align='center'>
                    {pagoRecebido[venda.recebido]}
                  </TableCell>
                  <TableCell align='center'>
                    {formatarDataHora(venda.criadoEm)}
                  </TableCell>
                  <TableCell className='text-end'>
                    <Acoes compra={venda} />
                  </TableCell>
                </TableRow>
              ))
            ) : listagem === null || items === null || clientes === null ? (
              <TableRow>
                <TableCell
                  className='text-center'
                  colSpan={9}
                  sx={{ height: '247.5px' }}
                >
                  <Typography gutterBottom>
                    Houve um erro ao carregar a listagem!
                  </Typography>
                  <Button onClick={tentarNovamente}>Tentar novamente</Button>
                </TableCell>
              </TableRow>
            ) : (
              <ListaSkeleton />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de compras cadastrados.
 */
export default function Vendas() {
  const [vendas] = useVendas();
  const [, , , clientesFetch, itemsFetch, , vendasFetch] = useFuncoes();

  /**
   */
  function geralFetch() {
    vendasFetch();
    clientesFetch();
    itemsFetch();
  }

  useEffect(() => {
    geralFetch();
  }, []);

  return (
    <NovaVendaProvider>
      <VisualizarVendaProvider>
        <AlterarVendaProvider>
          <AlterarStatusVendaProvider>
            <ExcluirVendaProvider>
              <Grid item xs={12} md={8} lg={9}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <Lista listagem={vendas} tentarNovamente={geralFetch} />
                  </Paper>
                </Grid>
              </Grid>
            </ExcluirVendaProvider>
          </AlterarStatusVendaProvider>
        </AlterarVendaProvider>
      </VisualizarVendaProvider>
    </NovaVendaProvider>
  );
}
