import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiProvider } from './Api';
import { AuthProvider } from './Auth';
import { ErrorHandlerProvider } from './ErrorHandler';
import { FuncoesProvider } from './FuncoesGerais';
import TemaProvider from './Tema';
import { UsuariosProvider } from './Usuarios';
import { FornecedoresProvider } from './Fornecedores';
import { ClientesProvider } from './Clientes';
import { ItemsProvider } from './Items';
import { ComprasProvider } from './Compras';
import { VendasProvider } from './Vendas';

export const HooksContext = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function HooksProvider({ children }) {
  return (
    <HooksContext.Provider value={null}>
      <TemaProvider>
        <BrowserRouter>
          <ErrorHandlerProvider>
            <AuthProvider>
              <ApiProvider>
                <UsuariosProvider>
                  <FornecedoresProvider>
                    <ClientesProvider>
                      <ItemsProvider>
                        <ComprasProvider>
                          <VendasProvider>
                            <FuncoesProvider>{children}</FuncoesProvider>
                          </VendasProvider>
                        </ComprasProvider>
                      </ItemsProvider>
                    </ClientesProvider>
                  </FornecedoresProvider>
                </UsuariosProvider>
              </ApiProvider>
            </AuthProvider>
          </ErrorHandlerProvider>
        </BrowserRouter>
        <ToastContainer />
      </TemaProvider>
    </HooksContext.Provider>
  );
}

HooksProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
