import { ListItemText } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { paramItemsMenu } from '../../uteis/Parametros';
import HomeIcon from '@mui/icons-material/Home';

/**
 * @return {object} retorna o menu lateral
 */
export function ItemsMenu() {
  return (
    <Fragment>
      <Link
        to={'/'}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <ListItemButton>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Página Inicial'} />
        </ListItemButton>
      </Link>
      {paramItemsMenu.map(({ url, icone, titulo }, index) => (
        <Link
          key={`item-manu-${index}`}
          to={url}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItemButton>
            <ListItemIcon>{icone}</ListItemIcon>
            <ListItemText primary={titulo} />
          </ListItemButton>
        </Link>
      ))}
    </Fragment>
  );
}

export default ItemsMenu;
