import { Button, Stack, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useCompras } from '../../hooks/Compras';
import { useFornecedores } from '../../hooks/Fornecedores';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useItems } from '../../hooks/Items';
import { formatarDataHora, formatarValorParaReais } from '../../uteis/Funcoes';
import { mobile, pagoRecebido } from '../../uteis/Parametros';
import { NovaVendaProvider } from '../Vendas/Acoes/Novo';
import Acoes from './Acoes';
import { AlterarCompraProvider } from './Acoes/Alterar';
import { ExcluirCompraProvider } from './Acoes/Excluir';
import { NovaCompraProvider, useNovaCompra } from './Acoes/Novo';
import { VisualizarCompraProvider } from './Acoes/Visualizar';
import { RelatorioCompra } from './Acoes/Relatorio';

const titulo = 'Compras';
const botaoNovoItem = 'Nova compra';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Motorista</TableCell>
      <TableCell align='center'>Bitola</TableCell>
      <TableCell align='center'>Total</TableCell>
      <TableCell align='center'>Ticket</TableCell>
      <TableCell align='center'>Nota</TableCell>
      <TableCell align='center'>Fornecedor</TableCell>
      <TableCell align='center'>Pago</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'9ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'3ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoFornecedorAberto] = useNovaCompra();
  const [items] = useItems();
  const [fornecedores] = useFornecedores();

  /**
   */
  function handleNovoFornecedor() {
    setNovoFornecedorAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        {listagem && items && fornecedores && (
          <Stack direction={'row'} spacing={2}>
            <RelatorioCompra />
            <Button variant='contained' onClick={handleNovoFornecedor}>
              {botaoNovoItem}
            </Button>
          </Stack>
        )}
      </Stack>
      <TableContainer>
        <Table size='small' aria-label='caption table'>
          <TableHead>
            <ColunasHead />
          </TableHead>
          <TableBody>
            {listagem && items && fornecedores ? (
              listagem.map((compra, index) => (
                <TableRow
                  key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                >
                  <TableCell align='center'>{compra.id}</TableCell>
                  <TableCell align='center'>
                    {items.find((item) => item.id == compra.motorista).nome}
                  </TableCell>
                  <TableCell align='center'>
                    {items.find((item) => item.id == compra.bitola).nome}
                  </TableCell>
                  <TableCell align='center'>
                    R${' '}
                    {formatarValorParaReais(
                      JSON.parse(compra.total).peso *
                        JSON.parse(compra.total).valor
                    )}
                  </TableCell>
                  <TableCell align='center'>{compra.ticket}</TableCell>
                  <TableCell align='center'>{compra.nota}</TableCell>
                  <TableCell align='center'>
                    {
                      fornecedores.find(
                        (fornecedor) => fornecedor.id == compra.fornecedor
                      ).nome
                    }
                  </TableCell>
                  <TableCell align='center'>
                    {pagoRecebido[compra.pago]}
                  </TableCell>
                  <TableCell align='center'>
                    {formatarDataHora(compra.criadoEm)}
                  </TableCell>
                  <TableCell className='text-end'>
                    <Acoes compra={compra} />
                  </TableCell>
                </TableRow>
              ))
            ) : listagem === null || items === null || fornecedores === null ? (
              <TableRow>
                <TableCell
                  className='text-center'
                  colSpan={10}
                  sx={{ height: '247.5px' }}
                >
                  <Typography gutterBottom>
                    Houve um erro ao carregar a listagem!
                  </Typography>
                  <Button onClick={tentarNovamente}>Tentar novamente</Button>
                </TableCell>
              </TableRow>
            ) : (
              <ListaSkeleton />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de compras cadastrados.
 */
export default function Compras() {
  const [compras] = useCompras();
  const [, , fornecedoresFetch, , itemsFetch, comprasFetch] = useFuncoes();

  /**
   */
  function geralFetch() {
    comprasFetch();
    fornecedoresFetch();
    itemsFetch();
  }

  useEffect(() => {
    geralFetch();
  }, []);

  return (
    <NovaVendaProvider>
      <NovaCompraProvider>
        <VisualizarCompraProvider>
          <AlterarCompraProvider>
            <ExcluirCompraProvider>
              <Grid item xs={12} md={8} lg={9}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <Lista listagem={compras} tentarNovamente={geralFetch} />
                  </Paper>
                </Grid>
              </Grid>
            </ExcluirCompraProvider>
          </AlterarCompraProvider>
        </VisualizarCompraProvider>
      </NovaCompraProvider>
    </NovaVendaProvider>
  );
}
