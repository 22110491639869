import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useItems } from '../../../hooks/Items';
import { inserirItemNoState } from '../../../uteis/Funcoes';
import PropTypes from 'prop-types';

const url = 'items/novo';
const tipoPorTipo = { 1: 'Bitola', 2: 'Motorista' };
const nomePorTipo = { 1: 'Valor', 2: 'Nome Completo' };

/**
 * Hooks manager
 * @param {number} tipo
 * @return {children} Hooks.
 */
export function NovoItem({ tipo }) {
  const [, setItems] = useItems();
  const [enviando, setEnviando] = useState(false);
  const [nome, setNome] = useState();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} e
   */
  function handleItemNome(e) {
    setNome(e.target.value);
  }

  /**
   */
  function handleCadastrarItem() {
    setEnviando(true);
    const data = { nome, tipo };

    api
      .post(url, data)
      .then((r) => {
        setItems((prev) => inserirItemNoState(r, prev));

        toast.success(tipoPorTipo[tipo] + ' cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Stack direction={'row'} spacing={2}>
      <TextField
        label={nomePorTipo[tipo]}
        size='small'
        onChange={handleItemNome}
      />
      <LoadingButton
        loading={enviando}
        variant='contained'
        onClick={handleCadastrarItem}
        disabled={!nome}
      >
        Cadastrar
      </LoadingButton>
    </Stack>
  );
}

NovoItem.propTypes = {
  tipo: PropTypes.number.isRequired,
};

export default NovoItem;
