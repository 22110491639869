import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CampoCep,
  CampoCnpj,
  CampoCpf,
  CampoTelefone,
} from '../../../componentes/Campo/Campo';
import { useApi } from '../../../hooks/Api';
import { useClientes } from '../../../hooks/Clientes';
import { inserirItemNoState } from '../../../uteis/Funcoes';

const url = 'clientes/novo';
const titulo = 'Novo Cliente';
const botaoCadastrarMensagem = 'Cadastrar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoClienteProvider({ children }) {
  const [aberto, setNovoClienteAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [, setClientes] = useClientes();
  const [tipo, setTipo] = useState(1);
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} e
   */
  function handleTipo(e) {
    setTipo(e.target.value);
  }

  /**
   */
  function handleFechar() {
    setNovoClienteAberto(false);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setClientes((prev) => inserirItemNoState(r, prev));

        toast.success('Cliente cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setNovoClienteAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    label='Tipo'
                    name='tipo'
                    value={tipo}
                    onChange={handleTipo}
                  >
                    <MenuItem value={1}>CPF</MenuItem>
                    <MenuItem value={2}>CNPJ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {tipo === 1 ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name='nome'
                      label='Nome Completo'
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                      <InputLabel htmlFor='identificacao'>CPF *</InputLabel>
                      <OutlinedInput
                        name='identificacao'
                        inputComponent={CampoCpf}
                        label='Cnpj *'
                        placeholder='123.456.789-00'
                        required
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      name='nome'
                      label='Nome Fantasia'
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                      <InputLabel htmlFor='identificacao'>CNPJ *</InputLabel>
                      <OutlinedInput
                        name='identificacao'
                        inputComponent={CampoCnpj}
                        label='Cnpj *'
                        placeholder='12.345.678/0001-90'
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name='ie'
                      label='Inscrição Estadual'
                      autoComplete='off'
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} variant='outlined'>
                  <InputLabel htmlFor='telefone'>Telefone *</InputLabel>
                  <OutlinedInput
                    name='telefone'
                    inputComponent={CampoTelefone}
                    label='Telefone *'
                    placeholder='00 90000-0000'
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='email'
                  label='E-mail'
                  autoComplete='off'
                  type='email'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} variant='outlined'>
                  <InputLabel htmlFor='cep'>Cep *</InputLabel>
                  <OutlinedInput
                    name='cep'
                    inputComponent={CampoCep}
                    label='Cep *'
                    placeholder='00000-000'
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='endereco'
                  label='Endereço'
                  autoComplete='off'
                  type='endereco'
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Informações Adicionais'
                  autoComplete='off'
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

NovoClienteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoCliente = () => {
  return useContext(Ctx);
};
