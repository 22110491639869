import FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Corrige a função structureClone para navegadores que não possuem suporte
if (typeof structuredClone !== 'function') {
  window.structuredClone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
  };
}

// Funções gerais

/**
 * @param {string} cookie cookie a ser retornado
 * @return {string} Cookie
 *  */
export function retornarCookie(cookie) {
  try {
    const cookieBrowser = document.cookie.split(cookie + '=')[1].split(';')[0];
    return cookieBrowser;
  } catch (e) {
    return null;
  }
}

/**
 * @param {string} cookie cookie a ser removido
 * @return {void}
 *  */
export function removerCookie(cookie) {
  document.cookie = cookie + '=' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

/**
 * @param {string} name Cookie
 * @return {string} cookie
 */
export function retornarCookieCodificado(name) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length == 2) {
    const vlu = parts.pop().split(';').shift();
    const decodeVlu = decodeURIComponent(vlu);
    const replaceVlu = decodeVlu.replace(/[+]/g, ' ');
    return JSON.parse(replaceVlu);
  } else return undefined;
}

/**
 * @param {number} numero
 * @return {string}
 */
function adicionarZero(numero) {
  // Adiciona um zero à esquerda se o número for menor que 10
  return numero < 10 ? '0' + numero : numero;
}

/**
 * @param {string} dataString
 * @return {string}
 */
export function formatarDataHora(dataString) {
  // Cria um objeto Date com a string fornecida
  const data = new Date(dataString);

  // Extrai os componentes da data
  const dia = adicionarZero(data.getDate());
  const mes = adicionarZero(data.getMonth() + 1);
  const ano = data.getFullYear();
  const hora = adicionarZero(data.getHours());
  const minuto = adicionarZero(data.getMinutes());
  const segundo = adicionarZero(data.getSeconds());

  // Retorna a data formatada
  return (
    dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundo
  );
}

/**
 * @param {object} item
 * @param {object} prev
 * @return {object}
 */
export function inserirItemNoState(item, prev) {
  const prevClone = structuredClone(prev);

  const novo = [...prevClone, item];

  return novo;
}

/**
 * @param {object} item
 * @param {object} prev
 * @return {object}
 */
export function alterarItemNoState(item, prev) {
  const prevClone = structuredClone(prev);

  const itemEncontrado = prevClone.findIndex((pItem) => pItem.id == item.id);

  if (itemEncontrado == -1) {
    return prev;
  }

  prevClone[itemEncontrado] = item;

  return prevClone;
}

/**
 * @param {object} id
 * @param {object} prev
 * @return {object}
 */
export function removerItemDoState(id, prev) {
  const prevClone = structuredClone(prev);

  const itemEncontrado = prevClone.findIndex((item) => item.id == id);

  if (itemEncontrado == -1) {
    return prev;
  }

  prevClone.splice(itemEncontrado, 1);

  return prevClone;
}

/**
 * @param {number} number
 * @return {string}
 */
export function formatarValorParaReais(number) {
  return number
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .replace('R$', '')
    .trim();
}

/**
 * @param {object} rows
 */
export function gerarPlanilhaExcel(rows) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  // Convert data with headers to sheet
  const ws = XLSX.utils.json_to_sheet(rows);

  // Create workbook and add sheet with data
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

  // Write workbook to buffer in xlsx format
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Convert buffer to Blob with specified MIME type
  const data = new Blob([excelBuffer], { type: fileType });

  const timeStamp = new Date()

  const dataString = `${timeStamp.getDate()}/${timeStamp.getMonth()}/${timeStamp.getFullYear()}`

  // Save data as a file with specified filename
  FileSaver.saveAs(data, 'relatorio-' + dataString, fileExtension);
}
