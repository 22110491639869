import {
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useFornecedores } from '../../../hooks/Fornecedores';
import { useItems } from '../../../hooks/Items';
import { formatarValorParaReais } from '../../../uteis/Funcoes';

const titulo = 'Visualizar Compra';
const botaoFecharMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function VisualizarCompraProvider({ children }) {
  const [alvo, setVisualizarCompra] = useState();
  const [items] = useItems();
  const [fornecedores] = useFornecedores();
  const [total, setTotal] = useState({ peso: null, valor: null });

  /**
   */
  function handleFechar() {
    setVisualizarCompra();
  }

  useEffect(() => {
    if (alvo) {
      setTotal(JSON.parse(alvo.total));
    }
  }, [alvo]);

  return (
    <Ctx.Provider value={[setVisualizarCompra]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Grid container spacing={2} className='fk-visualizar-form'>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Motorista</InputLabel>
                  <Select
                    label='Motorista'
                    name='motorista'
                    defaultValue={alvo.motorista}
                  >
                    {items
                      .filter((item) => item.tipo === 2)
                      .map((item, index) => (
                        <MenuItem
                          key={`item-map-motorista-${index}`}
                          value={item.id}
                        >
                          {item.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Bitola</InputLabel>
                  <Select
                    label='Bitola'
                    name='bitola'
                    defaultValue={alvo.bitola}
                  >
                    {items
                      .filter((item) => item.tipo === 1)
                      .map((item, index) => (
                        <MenuItem
                          key={`item-map-bitola-${index}`}
                          value={item.id}
                        >
                          {item.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  name='ticket'
                  label='Ticket'
                  autoComplete='off'
                  defaultValue={alvo.ticket}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  name='nota'
                  label='Nota'
                  autoComplete='off'
                  defaultValue={alvo.nota}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Fornecedor</InputLabel>
                  <Select
                    label='Fornecedor'
                    name='fornecedor'
                    defaultValue={alvo.fornecedor}
                  >
                    {fornecedores.map((item, index) => (
                      <MenuItem
                        key={`item-map-fornecedor-${index}`}
                        value={item.id}
                      >
                        {item.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider sx={{ width: '100%', opacity: 1 }} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label='Tonelada'
                  autoComplete='off'
                  type='number'
                  defaultValue={JSON.parse(alvo.total).peso}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  x
                </p>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label='Preço'
                  autoComplete='off'
                  type='number'
                  defaultValue={JSON.parse(alvo.total).valor}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>R$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  =
                </p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label='Valor total'
                  autoComplete='off'
                  value={
                    total.valor && total.peso
                      ? 'R$ ' + formatarValorParaReais(total.valor * total.peso)
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel required>Pago ?</InputLabel>
                  <Select
                    label='Pago ?'
                    name='pago'
                    defaultValue={alvo.pago}
                    required
                  >
                    <MenuItem value={0}>Não</MenuItem>
                    <MenuItem value={1}>Sim</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observacao'
                  autoComplete='off'
                  multiline
                  rows={8}
                  defaultValue={alvo.observacao}
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <Button type='button' variant='outlined' onClick={handleFechar}>
                {botaoFecharMensagem}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

VisualizarCompraProvider.propTypes = {
  children: PropTypes.node.required,
};

export const useVisualizarCompra = () => {
  return useContext(Ctx);
};
