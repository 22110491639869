import { Button, Stack, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFornecedores } from '../../hooks/Fornecedores';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { formatarDataHora } from '../../uteis/Funcoes';
import Acoes from './Acoes';
import { AlterarFornecedorProvider } from './Acoes/Alterar';
import { ExcluirFornecedorProvider } from './Acoes/Excluir';
import { NovoFornecedorProvider, useNovoFornecedor } from './Acoes/Novo';
import { mobile } from '../../uteis/Parametros';

const titulo = 'Fornecedores';
const botaoNovoItem = 'Novo Fornecedor';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>CPF ou CNPJ</TableCell>
      <TableCell align='center'>Nome</TableCell>
      <TableCell align='center'>Telefone</TableCell>
      <TableCell align='center'>E-mail</TableCell>
      <TableCell align='center'>Última alteração</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'13ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'9ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoFornecedorAberto] = useNovoFornecedor();

  /**
   */
  function handleNovoFornecedor() {
    setNovoFornecedorAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        <Button variant='contained' onClick={handleNovoFornecedor}>
          {botaoNovoItem}
        </Button>
      </Stack>
      <TableContainer>
        <Table size='small' aria-label='caption table'>
          <TableHead>
            <ColunasHead />
          </TableHead>
          <TableBody>
            {listagem ? (
              listagem.map((fornecedor, index) => (
                <TableRow
                  key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                >
                  <TableCell align='center'>{fornecedor.id}</TableCell>
                  <TableCell align='center'>
                    {fornecedor.identificacao}
                  </TableCell>
                  <TableCell align='center'>{fornecedor.nome}</TableCell>
                  <TableCell align='center'>{fornecedor.telefone}</TableCell>
                  <TableCell align='center'>{fornecedor.email}</TableCell>
                  <TableCell align='center'>
                    {formatarDataHora(fornecedor.criadoEm)}
                  </TableCell>
                  <TableCell align='center'>
                    {formatarDataHora(fornecedor.alteradoEm)}
                  </TableCell>
                  <TableCell className='text-end'>
                    <Acoes fornecedor={fornecedor} />
                  </TableCell>
                </TableRow>
              ))
            ) : listagem === null ? (
              <TableRow>
                <TableCell
                  className='text-center'
                  colSpan={7}
                  sx={{ height: '247.5px' }}
                >
                  <Typography gutterBottom>
                    Houve um erro ao carregar a listagem!
                  </Typography>
                  <Button onClick={tentarNovamente}>Tentar novamente</Button>
                </TableCell>
              </TableRow>
            ) : (
              <ListaSkeleton />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Fornecedores() {
  const [fornecedores] = useFornecedores();
  const [, , fornecedoresFetch] = useFuncoes();

  useEffect(() => {
    fornecedoresFetch();
  }, []);

  return (
    <NovoFornecedorProvider>
      <AlterarFornecedorProvider>
        <ExcluirFornecedorProvider>
          <Grid item xs={12} md={8} lg={9}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Lista
                  listagem={fornecedores}
                  tentarNovamente={fornecedoresFetch}
                />
              </Paper>
            </Grid>
          </Grid>
        </ExcluirFornecedorProvider>
      </AlterarFornecedorProvider>
    </NovoFornecedorProvider>
  );
}
