import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ForestIcon from '@mui/icons-material/Forest';
import PeopleIcon from '@mui/icons-material/People';
import React from 'react';

// Tema
export const paramTemaCorPrimaria = '#618833';
export const paramTemaCorSecundaria = '#7c7c7c';

// Urls
export const paramApiUrl = 'https://api.florestalkrieger.com.br';
export const paramSiteUrl = 'https://painel.florestalkrieger.com.br';

export const paramPrefixo = 'fk';

// Cookies
export const paramCookieAutenticacao = `${paramPrefixo}Auth`;
export const paramCookieMaster = `${paramPrefixo}Master`;

export const paramStatus = {
  1: 'O',
  2: 'K',
};

export const paramItemsMenu = [
  {
    url: '/compras',
    icone: <ForestIcon />,
    titulo: 'Compras',
  },
  {
    url: '/vendas',
    icone: <AttachMoneyIcon />,
    titulo: 'Vendas',
  },
  {
    url: '/bitolas',
    icone: <DonutLargeIcon />,
    titulo: 'Bitolas',
  },
  {
    url: '/clientes',
    icone: <ContactPageIcon />,
    titulo: 'Clientes',
  },
  {
    url: '/fornecedores',
    icone: <ContactPhoneIcon />,
    titulo: 'Fornecedores',
  },
  {
    url: '/motoristas',
    icone: <DriveEtaIcon />,
    titulo: 'Motoristas',
  },
  {
    url: '/usuarios',
    icone: <PeopleIcon />,
    titulo: 'Usuários',
  },
];

export const mobile = window.innerWidth < 768;

export const pagoRecebido = ['Não', 'Sim'];
