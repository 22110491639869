import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { removerCookie } from '../uteis/Funcoes';
import {
  paramApiUrl,
  paramCookieAutenticacao,
  paramCookieMaster,
} from '../uteis/Parametros';
import { useAuth } from './Auth';
import { useErrorHandler } from './ErrorHandler';
import Entrar from '../paginas/Entrar/Entrar';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://192.168.1.24:3333'
      : paramApiUrl,
  withCredentials: true,
});

export const ApiContext = createContext();

/**
 * Provider para a api
 * @param {any} children componente children
 * @return {void} retorna a página de acordo com a autenticação.
 */
export function ApiProvider({ children }) {
  const [mostrarErro, handleFechar] = useErrorHandler();
  const [usuario, setUsuario, , setMaster] = useAuth();
  const navigate = useNavigate();

  /**
   * Gerencia o erro da requisição
   * @param {object} e Erro
   * @param {object} supress Lista de códigos de erro a serem ignorados
   * @return {void}
   */
  function errorHandler(e, supress) {
    const errorCode = e?.response?.status;

    console.log(e)

    const handleUsuario = () => {
      document.cookie = removerCookie(paramCookieAutenticacao);
      document.cookie = removerCookie(paramCookieMaster);
      setUsuario(null);
      setMaster(null);
      navigate('/');
      handleFechar();
    };

    if (!supress?.includes(errorCode)) {
      switch (errorCode) {
        case 401:
          mostrarErro([
            'Sessão expirada',
            {
              conteudo: [
                'Sua sessão expirou. Por favor, faça login e tente novamente.',
              ],
              acoes: ['Ok'],
              funcoes: [handleUsuario],
            },
          ]);
          break;
        case 422:
          if (e?.response?.data?.errors) {
            const msg = e?.response?.data?.errors.map((item) => item.message);

            mostrarErro([
              'Dados inválidos',
              {
                conteudo: msg,
                acoes: ['Ok'],
                funcoes: [handleFechar],
              },
            ]);
          } else {
            toast.error(
              'Houve  erro no servidor. Por favor, tente novamente.',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                transition: Bounce,
              }
            );
          }
          break;
        case 500:
          toast.error('Houve  erro no servidor. Por favor, tente novamente.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
            transition: Bounce,
          });
          break;
        default:
          toast.error('Houve  erro no servidor. Por favor, tente novamente.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
            transition: Bounce,
          });
      }
    }
  }

  /**
   * Provider para a api
   * @param {any} children componente children
   * @return {children}
   */
  class ApiController {
    /**
     * Provider para a api
     * @param {string} url Url que será enviada a requisição
     * @param {object} body Body da requisição
     * @param {object} errorCodes Lista de codigos de erro a serem ignorar
     * @return {object} retorna a página de acordo com a autenticação.
     */
    post(url, body, errorCodes) {
      return new Promise((resolve, reject) => {
        api
          .post(url, body)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
            errorHandler(e, errorCodes);
          });
      });
    }
  }

  return (
    <ApiContext.Provider value={ApiController}>
      {usuario ? children : <Entrar />}
    </ApiContext.Provider>
  );
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useApi = () => {
  return useContext(ApiContext);
};
