import { Button, Stack, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useItems } from '../../hooks/Items';
import Acoes from './Acoes';
import { AlterarItemProvider } from './Acoes/Alterar';
import { ExcluirItemProvider } from './Acoes/Excluir';
import NovoItem from './Acoes/Novo';
import { mobile } from '../../uteis/Parametros';

const titulo = 'Motoristas';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Nome Completo</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'1ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'5ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {number} tipo
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ tipo, listagem, tentarNovamente }) {
  return (
    <React.Fragment>
      <Stack
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        {listagem ? (
          <NovoItem tipo={tipo} />
        ) : (
          <Stack direction={'row'} spacing={2}>
            <Skeleton
              variant='rounded'
              width={'210px'}
              height={'40px'}
              style={{ float: 'inline-end' }}
            />
            <Skeleton
              variant='rounded'
              width={'116.13px'}
              height={'40px'}
              style={{ float: 'inline-end' }}
            />
          </Stack>
        )}
      </Stack>
      <TableContainer>
        <Table size='small' aria-label='caption table'>
          <TableHead>
            <ColunasHead />
          </TableHead>
          <TableBody>
            {listagem ? (
              listagem
                .filter((item) => item.tipo === tipo)
                .map((item, index) => (
                  <TableRow
                    key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                  >
                    <TableCell align='center'>{item.id}</TableCell>
                    <TableCell align='center'>{item.nome}</TableCell>
                    <TableCell className='text-end'>
                      <Acoes item={item} />
                    </TableCell>
                  </TableRow>
                ))
            ) : listagem === null ? (
              <TableRow>
                <TableCell
                  className='text-center'
                  colSpan={7}
                  sx={{ height: '247.5px' }}
                >
                  <Typography gutterBottom>
                    Houve um erro ao carregar a listagem!
                  </Typography>
                  <Button onClick={tentarNovamente}>Tentar novamente</Button>
                </TableCell>
              </TableRow>
            ) : (
              <ListaSkeleton />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

Lista.propTypes = {
  tipo: PropTypes.number.isRequired,
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de motoristas cadastrados.
 */
export default function Motoristas() {
  const [items] = useItems();
  const [, , , , itemsFetch] = useFuncoes();
  const tipo = 2;

  useEffect(() => {
    itemsFetch();
  }, []);

  return (
    <AlterarItemProvider tipo={tipo}>
      <ExcluirItemProvider tipo={tipo}>
        <Grid item xs={12} md={8} lg={9}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Lista
                tipo={tipo}
                listagem={items}
                tentarNovamente={itemsFetch}
              />
            </Paper>
          </Grid>
        </Grid>
      </ExcluirItemProvider>
    </AlterarItemProvider>
  );
}
