import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useUsuarios } from '../../../hooks/Usuarios';
import { inserirItemNoState } from '../../../uteis/Funcoes';

const url = 'usuarios/novo';
const titulo = 'Novo Usuário';
const botaoCadastrarMensagem = 'Cadastrar';
const botaoCancelarMensagem = 'Cancelar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoUsuarioProvider({ children }) {
  const [aberto, setNovoUsuarioAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [, setUsuarios] = useUsuarios();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setNovoUsuarioAberto(false);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setUsuarios((prev) => inserirItemNoState(r, prev));

        toast.success('Usuário cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setNovoUsuarioAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name='usuario'
                  label='Usuário'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='senha'
                  label='Senha'
                  autoComplete='off'
                  type='password'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='senhaConfirmacao'
                  label='Repita a senha'
                  autoComplete='off'
                  type='password'
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

NovoUsuarioProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovoUsuario = () => {
  return useContext(Ctx);
};
